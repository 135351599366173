import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import {
  FormContainer,
  FormWrapper,
} from "./MetadataForm.elements";
import {UploadTitleBlock, ButtonWrapper, UploadButton } from "../UploadForm.elements";
import { TitleText } from "../../Util/Modal.elements";
import countries from "../../Util/countries.json";
import statesData from "../../Util/states.json";
import affiliates from "../../Util/nexstarAffilatesData.json";
import { usePackagesActions } from "@/store";
import { TextField, Select as MuiSelect, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import UploadStepper from "../UploadStepper";
import MuiClipDateRangePicker from "@/components/Clips/MuiClipDateRangePicker";

const MetadataForm = ({ files, handleBack, handleNext }) => {
  const [states, setStates] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const { uploadPackage } = usePackagesActions();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  useEffect(() => {
    if (selectedCountryCode) {
      // Update states based on selected country
      setStates(statesData[selectedCountryCode] || []);
      setValue("state", ""); // Reset state when country changes
    }
  }, [selectedCountryCode, setValue]);

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    console.log("Country code is: ", countryCode);
    const countryName = countries.find(c => c.code === countryCode).name;
    console.log("Country name is: ", countryName);
    
    setSelectedCountryCode(countryCode);
    setSelectedCountryName(countryName);
    
    setValue("country", countryCode); // Update react-hook-form value
    clearErrors("country"); // Clear error when user selects a valid option
  };

  const onSubmit = (data) => {
    const uploadMetadata = {
      ...data,
      country: selectedCountryName, // Use the country name in submission
    };
    uploadPackage({ files, uploadMetadata });
    handleNext();
    
    console.log("Form submitted:", uploadMetadata);
  };

  return (
    <FormContainer>
      <UploadTitleBlock>
        <TitleText>ADD METADATA</TitleText>
      </UploadTitleBlock>
      <UploadStepper step={2}/>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <TextField
            label="Package Name*"
            placeholder="Enter the package name"
            {...register("packageName", {
              required: "Package Name is required",
              maxLength: {
                value: 80,
                message: "Package Name must be less than 80 characters long",
              },
            })}
            error={!!errors.packageName}
            helperText={errors.packageName?.message}
          />
          <TextField
            label="Description*"
            placeholder="Description is required"
            {...register("description", {
              required: "Description is required",
              maxLength: {
                value: 200,
                message: "Package Name must be less than 200 characters long",
              },
            })}
            error={!!errors.description}
            helperText={errors.description?.message}
          />

          <FormControl>
            <InputLabel id="country-select-label">Country*</InputLabel>
            <MuiSelect
              labelId="country-select-label"
              placeholder="Select Country"
              label="Country*"
              {...register("country", {
                required: "Country is required",
              })}
              onChange={handleCountryChange}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </MuiSelect>
            {!!errors.country && <FormHelperText>{errors.country?.message}</FormHelperText>}
          </FormControl>

          <FormControl>
            <InputLabel id="state-select-label">State*</InputLabel>
            <MuiSelect
              labelId="state-select-label"
              placeholder="Select State"
              label="State*"
              {...register("state", {
                required: "State is required",
              })}
            >
              {states.map((state) => (
                <MenuItem key={state.code} value={state.name}>
                  {state.name}
                </MenuItem>
              ))}
            </MuiSelect>
            {!!errors.state && <FormHelperText>{errors.state?.message}</FormHelperText>}
          </FormControl>

          <TextField
            label="City*"
            placeholder="City is required"
            {...register("city", {
              required: "Enter the city",
            })}
            error={!!errors.city}
            helperText={errors.city?.message}
          />

          <FormControl>
              <Controller
                name="dateShot"
                control={control}
                defaultValue={undefined}
                rules={{ required: "Date is required" }}
                render={({ field }) => (
                  <MuiClipDateRangePicker
                    label="Shot Date*"
                    value={field.value}
                    onChange={(dateShot) => field.onChange(dateShot)}
                    format="YYYY/MM/DD"
                  />
                )}
              />
            {!!errors.dateShot && <FormHelperText>{errors.dateShot?.message}</FormHelperText>}
          </FormControl>

          <TextField
            label="Notes"
            multiline
            fullWidth
            {...register("notes")}
            error={!!errors.notes}
            helperText={errors.notes?.message}
          />

          <FormControl fullWidth>
            <InputLabel id="affilate-select-label">Nexstar Affiliate*</InputLabel>
            <MuiSelect
              labelId="affiliate-select-label"
              placeholder="Select Nexstar Affiliate"
              label="Nexstar Affiliate*"
              {...register("affiliate", {
                required: "Nexstar Affiliate is required",
              })}
            >
              {affiliates.map((affilate) => (
                <MenuItem key={affilate.name} value={affilate.name}>
                  {affilate.name} - {affilate.location}
                </MenuItem>
              ))}
            </MuiSelect>
            {!!errors.affilate && <FormHelperText>{errors.affilate?.message}</FormHelperText>}
          </FormControl>

        </FormWrapper>
        <ButtonWrapper>
          <UploadButton
            buttonState="next"
            type="submit"
            disabled={Object.keys(errors).length > 0}
          >
            Submit
          </UploadButton>
          <UploadButton variant="outlined" color="white" onClick={handleBack}>
            Back
          </UploadButton>
        </ButtonWrapper>
      </form>
    </FormContainer>
  );
};

export default MetadataForm;
