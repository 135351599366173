import React, { useState } from "react";
import VideoUpload from "./UploadSteps/VideoUpload/VideoUpload";
import MetadataForm from "./UploadSteps/MetadataForm";

// import {
//   ModalOverlay,
//   ModalContainer,
//   ModalBody,
//   ModalCloseButton,
// } from "../Util/Modal.elements";

const UploadInputGroup = ({ handleClose }) => {
  const [currentStep, setStep] = useState('videoUpload');
  const [files, setFiles] = useState([]);

  const renderContent = () => {
    if (currentStep === "videoUpload") {
      return <VideoUpload files={files} setFiles={setFiles} handleNext={() => setStep('metadataForm')} />;
    } else if (currentStep === "metadataForm") {
      return <MetadataForm files={files} handleBack={() => setStep('videoUpload')} handleNext={handleClose} />;
    }
    return null; // Fallback if the path doesn't match
  };

  return renderContent();

  // return show ? (
  //   <ModalOverlay>
  //     <ModalContainer>
  //       <ModalBody>
  //         <ModalCloseButton onClick={handleClose}>&times;</ModalCloseButton>
  //         {renderContent()}
  //       </ModalBody>
  //     </ModalContainer>
  //   </ModalOverlay>
  // ) : null;

};

export default UploadInputGroup;
