import http from "./http-common";
import { v4 as uuidv4 } from "uuid";
import { getAuthToken } from "./authService";

let abortController = null;

const upload = async (formData, onProgress) => {
  const data = new FormData();
  const token = await getAuthToken();

  if (!formData.files || formData.files.length === 0) {
    console.error("No files provided for upload.");
    throw new Error("No files provided for upload.");
  }

  let totalSize = 0;
  let uploadedSize = 0;
  const startTime = Date.now();

  formData.files.forEach((file) => {
    data.append("files", file);
    totalSize += file.size;
  });

  const uploadMetadata = {
    ...formData.uploadMetadata,
    uploadId: uuidv4(),
    startUploadTime: new Date().toISOString(),
  };

  data.append(
    "uploadMetadata",
    new Blob([JSON.stringify(uploadMetadata)], {
      type: "application/json",
    })
  );

  console.log("UploadService: Starting upload with metadata:", uploadMetadata);

  abortController = new AbortController();
  const { signal } = abortController;

  try {
    const response = await http.post("/api/v1/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      signal,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          uploadedSize = progressEvent.loaded;
          const percentCompleted = Math.round((uploadedSize / totalSize) * 100);

          const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
          const progressPercentage = uploadedSize / totalSize || 0;
          const estimatedTotalTime = elapsedTime / progressPercentage || 0;
          const remainingTime = Math.max(0, estimatedTotalTime - elapsedTime);

          onProgress(percentCompleted, remainingTime);
        }
      },
    });
    console.log("Upload successful:", response.data);
    return response.data;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log("Upload canceled:", error.message);
    } else {
      console.error("Upload failed:", error.response ? error.response.data : error.message);
    }
    throw error;
  }
};

const cancel = async (uploadId) => {
  try {
    const token = await getAuthToken();

    let response = null;

    try {
      response = await http.post('/api/v1/upload/cancel', { uploadId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Failed to cancel the upload on server side:", error.response ? error.response.data : error.message);
    }


    if (abortController) {
      abortController.abort();
      console.log("Upload canceled successfully.");
    }

    return response.data;
  } catch (error) {
    console.error("Failed to cancel the upload:", error.response ? error.response.data : error.message);
  }
};

const getFiles = async (props) => {
  const token = await getAuthToken();
  const params = {
    page: props.page,
    size: props.pageSize || 10,
    sort: 'createdAt,DESC',
  };

  if (props.contributors) {
    params.contributors = props.contributors;
  }
  if (props.affiliate) {
    params.affiliate = props.affiliate;
  }
  if (props.country) {
    params.country = props.country;
  }
  if (props.packageName) {
    params.packageName = props.packageName;
  }
  if (props.fromDate) {
    params.from = props.fromDate;
  }
  if (props.toDate) {
    params.to = props.toDate;
  }

  return http.get(`/api/v1/metadata`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

const UploadService = {
  upload,
  getFiles,
  cancel,
};

export default UploadService;
