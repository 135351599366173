import { useRef } from 'react';
import { Badge, Box, IconButton, Popper } from '@mui/material'
import { AbsCrossButton } from '@/components';
import { usePackagesActions, usePackagesState } from '@/store';
import { UploadProgressIcon, CheckCircleSmIcon, XCircleIcon } from '@/icons';
import {
  UploadProgressPanel,
  UploadProgressCard,
  UploadProgressCardContent,
  UploadProgressIconWrapper,
  UploadProgressInfo,
  UploadProgressTitle,
  UploadProgressMessage,
  UploadProgressCardRow,
  UploadProgressCardActions,
  UploadProgressButton,
  UploadProgressSecondButton
} from './UploadProgress.elements';
import { UploadProgressLoadingCard } from './UploadProgressLoadingCard';


export const UploadProgress = () => {
  const anchorElRef = useRef(null);
  const { isProgressDropdownOpen } = usePackagesState();
  const { setIsProgressDropdownOpen } = usePackagesActions();
  const toggleDropdown = () => setIsProgressDropdownOpen(!isProgressDropdownOpen);
  const { uploadingPackages, isRetryUploading } = usePackagesState();
  const { retryUploadPackage, deleteUploadingPackage } = usePackagesActions();

  return (
    <>
      <IconButton ref={anchorElRef} onClick={toggleDropdown}>
        <Badge badgeContent={uploadingPackages.length} color="secondary">
          <UploadProgressIcon />
        </Badge>
      </IconButton>
      <Popper
        anchorEl={anchorElRef.current}
        open={isProgressDropdownOpen}
        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
        sx={{ zIndex: 12 }}
      >
        <UploadProgressPanel>
          {uploadingPackages.length === 0 && (
            <Box>There are no active uploads right now</Box>
          )}
          {uploadingPackages.map((upload) => (
            <UploadProgressCard key={upload.uploadId}>
              <UploadProgressCardContent>
                {upload.status === 'loading' && (
                  <UploadProgressLoadingCard upload={upload} />
                )}
                {upload.status === 'succeeded' && (
                  <UploadProgressCardRow>
                    <UploadProgressIconWrapper as={CheckCircleSmIcon} status={upload.status} />
                    <UploadProgressInfo>
                      <UploadProgressTitle>{upload.uploadMetadata.packageName}</UploadProgressTitle>
                      <UploadProgressMessage>
                        Your file was successfully uploaded.
                      </UploadProgressMessage>
                    </UploadProgressInfo>
                    <AbsCrossButton onClick={() => deleteUploadingPackage(upload.uploadId)}/>
                  </UploadProgressCardRow>
                )}
                {upload.status === 'failed' && (
                  <UploadProgressCardRow>
                    <UploadProgressIconWrapper as={XCircleIcon} status={upload.status} />
                    <UploadProgressCardContent>
                      <UploadProgressInfo>
                        <UploadProgressTitle>{upload.uploadMetadata.packageName}</UploadProgressTitle>
                        <UploadProgressMessage>
                          Your file could not be uploaded. Would you like to try again?
                        </UploadProgressMessage>
                      </UploadProgressInfo>
                      <UploadProgressCardActions>
                        <UploadProgressButton onClick={() => retryUploadPackage(upload.uploadId)} disabled={isRetryUploading}>
                          Retry
                        </UploadProgressButton>
                        <UploadProgressSecondButton onClick={() => deleteUploadingPackage(upload.uploadId)}>
                          Cancel
                        </UploadProgressSecondButton>
                      </UploadProgressCardActions>
                    </UploadProgressCardContent>
                    <AbsCrossButton onClick={() => deleteUploadingPackage(upload.uploadId)}/>
                  </UploadProgressCardRow>
                )}
                {upload.status === 'canceled' && (
                  <UploadProgressCardRow>
                    <UploadProgressIconWrapper as={XCircleIcon} status={upload.status} />
                    <UploadProgressInfo>
                      <UploadProgressTitle>{upload.uploadMetadata.packageName}</UploadProgressTitle>
                      <UploadProgressMessage>
                        Your upload has been canceled.
                      </UploadProgressMessage>
                    </UploadProgressInfo>
                    <AbsCrossButton onClick={() => deleteUploadingPackage(upload.uploadId)}/>
                  </UploadProgressCardRow>
                )}
              </UploadProgressCardContent>
            </UploadProgressCard>
          ))}
        </UploadProgressPanel>
      </Popper>
    </>
  );
}
