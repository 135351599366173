import React, { useState } from "react";
import { Dropzone } from "./DropZone";
import {
  UploadTitleBlock,
  UploadTitleText,
  ButtonWrapper,
  UploadButton
} from "../../UploadForm.elements";
import UploadStepper from "../../UploadStepper";

const VideoUpload = ({ files, setFiles, handleNext }) => {
  const [isFilesAdded, setIsFilesAdded] = useState(false);

  const handleFilesAdded = (files) => {
    console.log("handleFilesAdded called with:", files);
    setIsFilesAdded(files.length > 0);
    console.log("isFilesAdded state updated to:", files.length > 0);
  };

  return (
    <>
      <UploadTitleBlock>
        <UploadTitleText>UPLOAD CLIPS</UploadTitleText>
      </UploadTitleBlock>
      <UploadStepper step={1}/>
      <Dropzone files={files} setFiles={setFiles} onFilesAdded={handleFilesAdded} />
      <ButtonWrapper>
        <UploadButton
          onClick={handleNext}
          disabled={!isFilesAdded}
        >
          Continue
        </UploadButton>
      </ButtonWrapper>
    </>
  );
};

export default VideoUpload;
