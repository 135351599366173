import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "@emotion/styled";

import { UploadIcon, 
  UploadIconBlock, 
  FileItemContainer,
  DropTitleText,
  DropNormalText,
  FileList,
  FilePath,
  FileSize,
  DropHighlightedText,
  FileItemIcon,
  DeleteIcon,
  FileDelimiter
} from "../../UploadForm.elements";
import {FormatSize} from "../../../Util/Utils.elements";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#B2B2B2D9";
};

const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 3px;
  border-radius: 8px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #202C3D8C;
  color: #fff;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`;

export const Dropzone = ({ files, setFiles, onFilesAdded }) => {
  // const [files, setFiles] = useState([]);
  // const [path, setPath] = useState([]);
  // console.log(path);

  useEffect(() => {
    onFilesAdded(files) // Notify parent about accepted files
  }, [onFilesAdded, files])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // accept: ['text/csv', 'application/vnd.ms-excel']
    onDrop: (acceptedFiles) => {
      console.log("Files added:", acceptedFiles);
      
      setFiles((prevFiles) => {
        const existingFileNames = prevFiles.map(file => file.name);
        const filteredNewFiles = acceptedFiles.filter(file => !existingFileNames.includes(file.name));
        const newFiles = [...prevFiles, ...filteredNewFiles];
        // onFilesAdded(newFiles); 
        return newFiles
      });
  },
  });

  // const handleDelete = (file) => {
  //   setFiles((prevFiles) => prevFiles.filter((f) => f !== file)); // Remove file from state
  // };

  const handleDelete = (file) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((f) => f !== file);
      // Notify parent about the updated files
      // onFilesAdded(updatedFiles);
      return updatedFiles;
    });
  };

  const acceptedFileItemsWithDel = files.map((file, i) => (
    <>
      {i !== 0 && <FileDelimiter />}
      <FileItemContainer key={file.path}>
        <FileItemIcon />
        <FilePath>{file.path}</FilePath>
        <FileSize><FormatSize sizeInBytes={file.size} /></FileSize>
        <DeleteIcon onClick={() => handleDelete(file)}>Delete</DeleteIcon>
      </FileItemContainer>
    </>
  ))

  console.log(acceptedFileItemsWithDel ? acceptedFileItemsWithDel : []);

  return (
    <div>
      <div>
        <section>
          <DropContainer
            {...getRootProps({
              className: "dropzone",
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <DropTitleText>Add your assets</DropTitleText>
              <UploadIconBlock>
              <UploadIcon />
              </UploadIconBlock>
              <DropNormalText>
                Drag & drop your files or
                {' '}
                <DropHighlightedText>
                  browse files
                </DropHighlightedText>
              </DropNormalText>
          </DropContainer>
          <div style={{ padding: 5 }}></div>
          <FileList>
            {acceptedFileItemsWithDel}
          </FileList>
          <div style={{ padding: 10 }}></div>
        </section>
      </div>
    </div>
  );
};
