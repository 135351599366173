import React from 'react';
import { StepperContainer, Step, StepCircle, StepLine } from './UploadForm.elements';

const UploadStepper = ({ step }) => {
  return (
    <StepperContainer>
      {/* Step 1: Upload Files */}
      <Step>
        <StepCircle active={step >= 1}>1</StepCircle> {/* Circle 1 is active if step >= 1 */}
      </Step>

      {/* Line between steps */}
      <StepLine active={step === 2} /> {/* Line is active only if step === 2 */}

      {/* Step 2: Add Description */}
      <Step>
        <StepCircle active={step === 2}>2</StepCircle> {/* Circle 2 is active only if step === 2 */}
      </Step>
    </StepperContainer>
  );
};

export default UploadStepper;